<template>
  <div>
    <div class="w-title" style="margin-bottom: 5px;">

      <nav class="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <router-link to="/">Accueil</router-link>
          </li>
          <li>
            <router-link to="/categories">Catégories</router-link>
          </li>
        </ul>
      </nav>

      <h1 class="title">Catégories</h1>
      <p>Aperçu de notre catalogue trié par catégories.</p>
    </div>

    <div style="margin: 20px" class="tile is-ancestor" v-for="(categories, index) in sortedCategories" :key="index">
      <div class="tile is-parent is-3" v-for="(category, index) in categories" :key="index">
        <article class="tile is-child box notification has-background-link-light">
          <p class="title is-5-tablet">{{ category.name }}</p>
          <p class="subtitle" style="font-size: 16px">{{ category.description }}</p>
            <img :alt="category.name" :src="category.image" style="object-fit: contain; height: 300px">
          <b-button tag="router-link"
                    :to="'/categories/' + category.slug"
                    type="is-info"
                    class="is-outlined is-fullwidth"
                    style="margin-top: 5px">
            Accéder
          </b-button>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Categories",
  data() {
    return {
      sortedCategories: []
    }
  },
  computed: {
    ...mapGetters("data", ["getCategories"])
  },
  mounted() {
    this.loadCategories()
  },
  methods: {
    loadCategories() {
      let cat = this.getCategories
      let categories = cat === undefined ? [] : cat.sort((a, b) => a.name.localeCompare(b.name));
      let length = categories.length
      let sortedCategories = []
      let tempArr = []
      let y = 0

      for (let i = 1; i <= length; i++) {
        tempArr.push(categories[i - 1])

        // 4 = card size
        if (i % 4 === 0) {
          sortedCategories[y] = tempArr
          tempArr = []
          y++
        }
      }

      if (tempArr.length !== 0)
        sortedCategories[y] = tempArr

      this.sortedCategories = sortedCategories
    }
  }
}
</script>

<style scoped>

</style>